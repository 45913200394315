import moment from "moment";

const formatTimestampToDescription = (timestamp) => {
    if (!timestamp) return null;

    return (
        moment(timestamp.replace(/ /g, "T")).format("MMMM Do YYYY, h:mm A") ||
        timestamp
    );
};

const formatTimestampToStandardFormat = (timestamp) => {
    const format = "YYYY-MM-DD HH:mm:ss";
    if (timestamp) {
        return moment(timestamp).format(format);
    }

    return null;
};

const formatTimestampToSchedule = (timestamp) => {
    if (!timestamp) return null;

    return (
        moment(timestamp.replace(/ /g, "T")).format("MMM D YYYY - h:mm A") ||
        timestamp
    );
};

const currentDate = () => {
    return moment().format();
};

const toUTC = (localDate) => {
    const format = "YYYY-MM-DD HH:mm:ss";
    return moment.utc(moment(localDate)).format(format);
};

const toLocalDate = (utcDate) => {
    const format = "YYYY-MM-DD HH:mm:ss";
    return moment.utc(utcDate, format).local().format(format);
};

const toStandardDate = (date) => {
    if (!date) return null;
    return moment(date.replace(/ /g, "T")).format("YYYY-MM-DD") || date;
};

const toSimpleDate = (timestamp) => {
    if (!timestamp) return null;
    return (
        moment(timestamp.replace(/ /g, "T")).format("MMMM D, YYYY") || timestamp
    );
};

const getSpecificDayOfMonth = (type, month) => {
    // const year = moment().year();
    // month = month
    //     ? parseInt(moment().month(month.trim()).format("M"))
    //     : moment().month() + 1;
    // let dateInstance = moment(`${year}-${month}-01`);
    // let date = null;
    // if (type === "start") {
    //     date = dateInstance.startOf("month").format();
    // } else if (type === "end") {
    //     date = dateInstance.endOf("month").format();
    // }

    const year = moment().year();
    month = month
        ? parseInt(moment().month(month.trim()).format("M"))
        : moment().month() + 1;
    const dateInstance = moment();
    let date = null;
    if (type === "start") {
        date = dateInstance.startOf("month");
    } else if (type === "start") {
        date = dateInstance.endOf("month");
    } else {
        date = currentDate();
    }

    return formatTimestampToStandardFormat(date);
};

const dateUtility = {
    formatTimestampToDescription,
    formatTimestampToStandardFormat,
    formatTimestampToSchedule,
    currentDate,
    toUTC,
    toLocalDate,
    toSimpleDate,
    toStandardDate,
    getSpecificDayOfMonth,
};

export default dateUtility;
